import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Button from "../components/ui/Button";

function ResetPassword() {
  const [data, setData] = useState({ password: "", password_confirmation: "", email: "", token: "" });
  const [errors, setErrors] = useState({});
  const [errorsMessages, setErrorsMessages] = useState([]);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [token, setToken] = useState("");
  const location = useLocation();
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const email = params.get("email");
    setToken(params.get("token"));
    setData((prevData) => ({ ...prevData, email: email }));
  }, [location]);

  function validateInputs(inputs) {
    let errors = {};
    if (!inputs.password) {
      errors.password = "La contraseña es obligatoria.";
    }
    if (!inputs.password_confirmation) {
      errors.password_confirmation = "Confirmar contraseña es obligatoria.";
    }
    if (inputs.password_confirmation !== inputs.password) {
      errors.password_confirmation = "Las contraseñas no coinciden.";
    }
    return errors;
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorsMessages([]);
    setErrors({});

    const validationErrors = validateInputs(data);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      console.log("validationErrors", validationErrors);
    } else {
      fetch(`${API_BASE_URL}/reset-password/${token}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json().then((body) => ({ status: response.status, body })))
        .then((result) => {
          const { status, body } = result;
          if (status === 200) {
            // Mensaje de confirmación
            setData({ email: "", password: "", password_confirmation: "" }); //
            setErrors({});
            setIsSuccessful(true);
          } else {
            const errors = body.errors || {};
            setErrorsMessages(Object.values(errors).flat());
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  return (
    <div className="h-[calc(100vh-100px)] flex justify-center items-center  flex-col">
      {isSuccessful ? (
        <div className="w-1/2 bg-white p-10 rounded-xl">
          <p className="text-3xl text-primary font-bold">¡Gracias!</p>
          <p className="mt-10">Hemos cambiado tu contraseña correctamente. Recibirás un correo de confirmación</p>
          <div className="mt-10">
            <Button href="/login" type="primary" classButton="w-fit mx-auto">
              Iniciar sesión
            </Button>
          </div>
        </div>
      ) : (
        <div>
          <h1 className="text-3xl font-bold text-primary">Cambiar contraseña</h1>
          <div className="flex flex-col gap-4 justify-center items-center">
            <form onSubmit={handleSubmit} className="mt-12">
              <div className="flex flex-col gap-4 justify-center items-center">
                <input
                  type="email"
                  className="w-full rounded-full disabled:opacity-50 px-4 py-4 transition-all ease-in-out duration-150"
                  value={data.email}
                  readOnly
                  disabled
                  placeholder=""
                />
                <input
                  type="password"
                  className="w-full rounded-full px-4 py-4 transition-all ease-in-out duration-150"
                  value={data.password}
                  onChange={(e) => setData({ ...data, password: e.target.value })}
                  placeholder="Introduce tu nueva contraseña"
                />

                <input
                  type="password"
                  className="w-full rounded-full px-4 py-4 transition-all ease-in-out duration-150"
                  value={data.password_confirmation}
                  onChange={(e) => setData({ ...data, password_confirmation: e.target.value })}
                  placeholder="Introduce tu nueva contraseña"
                />
                <button
                  type="submit"
                  className="bg-primary w-full button flex items-center justify-center gap-2 text-white stroke-white rounded-full px-24 py-4 hover:bg-opacity-90 transition-all ease-in-out duration-150"
                >
                  Guardar contraseña
                </button>
                <div className="flex flex-col justify-center text-center">
                  <div className="flex gap-2 mx-auto">
                    <span>¿No tienes cuenta?</span>
                    <a href="/register" className="underline text-primary cursor-pointer">
                      Regístrate aquí
                    </a>
                  </div>
                </div>
                <div className="w-full flex flex-col text-center">
                  {
                    <p className="w-full text-xs text-center text-red-500">
                      {validateInputs(data).password || validateInputs(data).password_confirmation}
                    </p>
                  }
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default ResetPassword;
