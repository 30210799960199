import React from "react";
import { Helmet } from "react-helmet-async";

const CategorySchema = ({ category }) => {
  const { name, description, slug, seoText } = category;

  const schemaData = {
    "@context": "https://schema.org/",
    "@type": "CollectionPage",
    "name": name,
    "description": description || seoText || "Descripción no disponible",
    "url": `https://www.elalquilador.com/alquiler/${slug}`,
  };

  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
    </Helmet>
  );
};

export default CategorySchema;
